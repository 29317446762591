.btn {



}

.dropdown-toggle {
     background-color: $gray-200 !important;
}

@media(min-width: 600px) {
     .btn-sm-inline {
          display: inline-block;
          width: auto;
     }
}