.alert {

    &.alert-squared {
        border-radius: 0;
    }

}

.sweet-alert {
    h2, p {
        // color: $offBlack !important;
    }
}