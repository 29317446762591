
h1,h2,h3,h4,h5,h6 {
    font-family: 'Heebo', sans-serif !important;
}


.text-gradient-success {
    color: $green;
    background: linear-gradient(to right, darken($green, 20%), $green);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-info {
    color: $green;
    background: linear-gradient(to right, darken($teal, 20%), $teal);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient-warning {
    color: $green;
    background: linear-gradient(to right, darken($orange, 20%), $orange);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient-danger {
    color: $green;
    background: linear-gradient(to right, darken($red, 20%), $red);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-info-original {
    color: $tealOriginal !important;
}

.overflow-text {
    margin-right: -150px;
}

.text-right .overflow-text {
    margin-left: -150px;
    margin-right: inherit
}

.text-lg-left {
    text-align: inherit !important;
}
.text-lg-center {
    text-align: inherit !important;
}
.text-lg-right {
    text-align: inherit !important;
}

.text-sub {
    font-size: 12px !important;
}

.text-italic {
    font-style: italic;
}

.text-line-through {
    text-decoration: line-through;
}

@include media-breakpoint-up(lg) {
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
}

.display-3 {
    line-height: 1.2;
}

.text-darker {
    color: #222;
}