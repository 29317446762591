audio {
    filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(6%);
    height: 30px;
}
  
select {
    -webkit-appearance: none !important;
}

label {
    margin-bottom: $spacer / 2.5;
}

.form-control {
    background: $input-bg !important;
    padding-left: $spacer;
    padding-right: $spacer;
    min-height: 38px;
    border-color: #aaa;
    font-size: $font-size-base * .9;
    // color: $offBlack;

    &.is-valid {
        background-color: $white !important;
    }

    &:disabled {
        background-color: $gray-300 !important;
        opacity: 0.7;
    }

    &:focus {
        // color: $offBlack;
        background-color: #fff;
        border-color: #06405C;
        outline: 0;
        box-shadow: $box-shadow-sm;
    }
    
}


.rdtPicker .rdtTimeToggle:before {
    content: "Time Select";
}


.form-group {
    margin-bottom: $spacer;
}

.custom-checkbox .custom-control-label {

    &::before {
        top: 3px;
    }

    &::after {
        top: 3px;
    }

}

.react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;

        input {
            width: 100%;
            border-radius: 0;
            color: $input-color;
            background: $input-bg;
            padding-left: $spacer;
            padding-right: $spacer;
            min-height: 38px;
            border-color: hsl(0,0%,80%);
            border: solid 1px $input-border-color;
            border-width: $input-border-width;
            box-shadow: $input-box-shadow;

            &:active {
                background: $input-bg;
            }

        }

    }

}

.date-picker-wrapper {

    input {
        position: relative;
        top: -2px;
    }

    &.invalid {
        input {
            border: solid 1px $orange;
        }
    }

    &.valid {
        input {
            border: solid 1px $green;
        }
    }
}

.react-datepicker-popper {
    z-index: 99999 !important;
}


.bootstrap-tagsinput {

    width: 100%;

    .tag {
        margin-bottom: $spacer;
    }

    input {

        // margin-top: $spacer;
        // transition: all 0.15s ease-in-out;
        // height: calc(1.875rem + 2px);
        // padding: 0.1875rem 0.3rem;
        // line-height: 1.5;
        // color: #8898aa;
        // border: 1px solid #dee2e6;
        // background: $input-bg;

        // &:focus {
        //     color: #8898aa;
        //     background-color: #fff;
        //     border-color: #06405C;
        //     outline: 0;
        //     box-shadow: $box-shadow-sm;
        // }

        border: solid 1px $gray-200;
        border-radius: $border-radius;
        width: 100%;
        display: block;
        margin-top: 0;
        background: $input-bg;
        padding-left: $spacer;
        padding-right: $spacer;
        min-height: 38px;
        border-color: hsl(0,0%,80%);
        font-size: $font-size-base * .9;

        &:focus {
            border: solid 1px $gray-200;
            background: $white
        }

    
        &:disabled {
            background-color: $gray-300 !important;
            opacity: 0.7;
        }
    

    }

    .badge.badge-warning {
        background: $orange;
    }

    ul {
        position: absolute;
        padding-left: 0;
        z-index: 1000;
        width: 100%;
        box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);

        li {
            background-color: $gray-100;
            list-style: none;
            padding: $spacer * .5 $spacer;
            width: 100%;
            border-bottom: solid 1px $gray-400;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                background: $gray-200;
            }

        }

    }

}

#end-call-modal .react-quill-invalid, .react-quill-invalid, .quill-invalid {

    .quill .ql-container .ql-editor {
        border-top: solid 1px $red !important;
        border-bottom: solid 1px $red !important;
        border-right: solid 1px $red !important;
        border-left: solid 1px $red !important;
        border-color: solid 1px $red !important;
    }

}

.react-quill-toolbar-hide {
    .ql-toolbar {
        display: none !important;
    }

    .ql-toolbar + .ql-container .ql-editor {
        border-radius: $border-radius;

    }

}

.react-select {

    &.invalid {
        border: solid 1px $orange;

        div {
            border: none;
        }
    }

    div {
        background: $input-bg;
        color: $input-color;
        font-size: $font-size-base * .9;
    }

}

.label-block {
    label {
        display: block;
    }
}


@media(max-width: 768px) {
    .w-50.form-group {
        width: 100% !important;
    }
}

@media(max-width: 576px) {

    select, input, textarea {
        font-size: 16px !important;
    }

    .form-control  {

        padding: .2rem .7rem !important;
        height: calc(2rem + 2px) !important;

        select, input, textarea {
            font-size: 16px !important;
        }
    }

}


.archk-select {

    position: relative;

    ul {
        display: none;
        padding-left: 0;
        list-style: none;
        position: absolute;
        width: 100%;
        max-height: 150px;
        overflow: auto;
        left: 0;
        right: 0;
        box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
        z-index: 2;
        li {
            background: $gray-200;
            padding: 6px 12px;
            cursor: pointer;
            &:hover {
                background: white;
            }

        }

    }

}


.collections {
    .date-picker-wrapper input {
        width: 100%;
    }
}