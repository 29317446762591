.archk-calendar-booking {


    .archk-calendar-wrapper {
        max-width: 100%;
        width: 100%;
        max-height: 500px;
        overflow: auto;
        // background-color: $white;


        // padding: 20px;
        // border: solid 1px $gray-200;
    }

    .archk-calendar-move {
        padding: 4px 8px;
        border-radius: 100%;
        background-color: $primary;
        color: $white !important;
        cursor: pointer;
        margin-left: 10px;
    }

    .archk-calendar-header {
        // border-bottom: solid 1px $gray-200;
        padding: 10px 20px;

        .archk-calendar-header-back {
            padding: 10px;
            position: absolute;
            top: 10px;
            left: 10px;
            color: $primary;
        }
    }
    
    .archk-calendar-body {
        padding: 10px 20px;
        padding-top: 0;
    }

    .archk-calendar-footer {
        padding: 10px 20px;
    }

    .archk-calendar-select-time-wrapper {
        border: solid 1px $primary;
        background: none;
        color: $primary;
        padding: 5px;
        margin-bottom: 10px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            background: $primary;
            color: $white;
        }
    }

    .skeletal-load {

        border: solid 1px $orange;
        background: lighten($orange, 25);
        color: $white;
        filter: blur(1px);
        // The shine that's going to move across the skeleton:
        background-image:			
                linear-gradient(
                    90deg, 
                    rgba(#fff, 0), 
                    rgba(#fff, 0.5),
                    rgba(#fff, 0)
                );
        background-size: 40px 100%; // width of the shine
        background-repeat: no-repeat; // No need to repeat the shine effect
        background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
        animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
    }

    @keyframes shine {
        to {
            // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
            background-position: right -40px top 0;
        }
    }


    .table {

        margin-bottom: 0;
   
        tr, td, th {
            border-top: none;
            border-bottom: none;
        }

        td {
            padding: 0;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        button {
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            width: 40px;
            height: 40px;
            text-align: center;

            background: lighten($primary, 12);
            font-weight: bold;
            color: $white;
            // border: solid 1px lighten($primary, 25);
            border-radius: 100%;
            padding: 0;

            &:hover {
                background: $primary;
                cursor: pointer;
            }
        }

        button.disabled,
        button:disabled,
        button[disabled]{
            color: $gray-500;
            background: none;
            &:hover {
                color: $gray-400;
                cursor: default;
                background: none;
            }
            font-weight: normal;
            border: none;
        }

    }

}

.archk-calendar-booking-modal {
    
    .archk-calendar-booking-modal-timezone {
        cursor: pointer;

        &.active {
            color: $white;
            background: $info;
            font-weight: bold;
        }

        &:hover {
            background: lighten($info, 35);
            // color: $offBlack;
        }
    }
}